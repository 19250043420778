import React from "react"

import ProductsRow from "./ProductRow"
import AuthorInfo from "./AuthorInfo"
import WhyUse from "./WhyUse"
import Benefits from "./Benefits"

const AuthorData = {
  name: "Written by Jamil Florusse",
  created: "Updated 18/09/2020",
  image: "/Jamil.png",
}



const ProductsData = [
  {
    title: "Candidate Screening",
    description:
      "Independent Candidate Screening solution, with e-mail notifications.",
  },
  {
    title: "Integrations",
    description:
      "Integrated Candidate Screening solution. Get our product integrated into your ATS.",
  },
  {
    title: "Whitelabel",
    description: "Whitelabel Partnership with any ATS.",
  },
]


interface LandingPageContectProps {}

const LandingPageContect: React.FunctionComponent<
  LandingPageContectProps
> = ({}): React.ReactElement => {
  return (
    <>
      <AuthorInfo data={AuthorData} />
      <ProductsRow data={ProductsData} />
      <WhyUse />
      <Benefits />
    </>
  )
}

export default LandingPageContect
