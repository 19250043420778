import React from "react"
import styled from "styled-components"

import FlipbaseAccordion from "../../../FlipbaseAccordion"

const CollapseData = [
  {
    heading: "We are integrated in your recruitment system / ATS",
    subhead:
      "With our experience, we are now masters in integrating in recruitment systems. In this way we (almost) certainly integrate into yours, which makes a big difference for you.",
  },
  {
    heading: "Lower costs",
    subhead:
      "Avoid preparing, scheduling and conducting unnecessary live interviews. This saves a lot of valuable time for both you and the candidate. This allows you to focus on other important matters.",
  },
  {
    heading: "Better cooperation",
    subhead:
      "Recruit together, by making other colleagues part of processes and interaction.",
  },
  {
    heading: "Higher quality of the candidates",
    subhead:
      "Don't miss out on hidden talent, by also looking at the soft skills of your candidates.",
  },
  {
    heading: "We are flexible",
    subhead:
      "Since you can add video at every step of your recruitment process, our tool is very flexible. This way you can also decide for yourself which vacancy you want to use video for, because that is entirely up to you. There will probably be a number of vacancies for which video is slightly less suitable.",
  },
]

interface BenefitsProps {}

const Benefits: React.FunctionComponent<
  BenefitsProps
> = ({}): React.ReactElement => {
  return (
    <>
      <BenefitsWrapper>
        <WhatIsFlipabseTitle>
          What are the benefits for you and your colleagues?
        </WhatIsFlipabseTitle>
        <FlipbaseAccordion data={CollapseData} />
      </BenefitsWrapper>
    </>
  )
}

const BenefitsWrapper = styled.div`
  padding-bottom: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  max-width: 1060px;
  text-align: center;
`

const WhatIsFlipabseTitle = styled.h3`
  font-family: FreightSans;
  margin-bottom: 64px;
  font-size: 42px;
  line-height: 1.23;
  font-weight: 600;
  color: #000;
  opacity: 0.8;
`

export default Benefits
