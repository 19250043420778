import React from "react"
import styled from "styled-components"

interface WhyUseProps {}

const WhyUse: React.FunctionComponent<WhyUseProps> = ({
  data,
}): React.ReactElement => {
  return (
    <>
      <WhyFlipabseWrapper>
        <TextWrapper>
          <WhatIsFlipabseTitle> Why should you choose us?</WhatIsFlipabseTitle>
          <WhatIsFlipabseDescription>
            Our integrated technology lets you save time and money and find the
            perfect candidates. Below you can see in which steps of your
            recruitment process video can be used.
          </WhatIsFlipabseDescription>
        </TextWrapper>
        <SchemeImage src="/scheme_image.png" />
        <TextWhyUse>
          <p>
            You can use video in many different steps of the recruitment
            process. For example, you can use video in the{" "}
            <span>application form</span> to immediately ask the candidate to
            introduce themself or to explain their motivation in video.
          </p>
          <p>
            In addition, it is possible to use video in the{" "}
            <span>second round/pre-selection, </span>
            after someone has applied. Here you can ask your candidate to record
            a short video or to answer several questions.
          </p>
          <p>
            As you can see above, these are just examples. You can actually use
            video at any time.
          </p>
        </TextWhyUse>
      </WhyFlipabseWrapper>
    </>
  )
}

const WhyFlipabseWrapper = styled.div`
  padding-bottom: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  max-width: 1060px;
`

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 105px;
`

const WhatIsFlipabseTitle = styled.h3`
  font-family: FreightSans;
  margin-bottom: 40px;
  font-size: 42px;
  line-height: 1.23;
  font-weight: 600;
  color: #000;
  opacity: 0.8;
`

const WhatIsFlipabseDescription = styled.p`
  font-family: FreightSans;
  font-size: 20px;
  max-width: 500px;
  text-align: center;
`

const SchemeImage = styled.img``

const TextWhyUse = styled.p`
  font-family: FreightSans;
  padding: 24px 0;
  font-size: 20px;
  line-height: 1.55;
  color: #000;
  opacity: 0.8;
  max-width: 800px;
  margin: 0 auto;
  span {
    font-weight: 700;
  }
  br {
    margin: 12px 0;
  }
  p {
    margin: 12px 0;
  }
`

export default WhyUse
