import React from 'react';
import styled from 'styled-components';
import { Collapse } from 'antd';
import {colors} from '../../styles/constants'

const FlipbaseAccordion = ({
    data,
    accordionOpt
}) => {
  const { Panel } = Collapse;
  
  return (
    <StyledCollapse 
      defaultActiveKey={['0']}      
      expandIconPosition={"right"}
      accordion={true}  
    >
    {data.map((item, index) => {
        return (
          <Panel header={item.heading} key={index}>
            <WrapperContent>
              <MiniLogoWrapper>
                <img src="/miniLogo.png" alt="mini-logo" />
              </MiniLogoWrapper>
              
              <p> {item.subhead} </p>
            </WrapperContent>
          </Panel>
        );
      })}
  </StyledCollapse>
  );
};

export default FlipbaseAccordion;





const StyledCollapse = styled(Collapse)`
    border:none !important;
    text-align:left;
    width:100% !important;
    max-width:800px !important;
    padding:16px !important;
    border-radius:6px !important;
    background-color:${colors.mainLight} !important;
    @media (max-width: 768px) {
      padding:24px 0 !important;
    }
    @media (max-width: 576px) {
      padding:16px 0 !important;
    }
    .ant-collapse-item {
      max-width:800px !important;
      border: 1px solid ${colors.mainColor} !important;
      border-radius: 3px;
      background-color: #FFFFFF;
      margin:20px;
      padding:8px;
      @media (max-width: 1024px) {
        margin:12px;
      }
      @media (max-width: 768px) {
        margin:16px;
        padding:32px;
      }
      @media (max-width: 576px) {
        padding:24px;
      }
    }
    .ant-collapse-content {
        border-top:none !important;
    }
    .ant-collapse-header {
        font-family: FreightSans !important;
        font-size: 24px;
        max-width:600px;
        font-weight: 600;
        color: #000;
        opacity:0.8;
    }
    .ant-collapse-arrow {
      color: #A62651 !important;
      height: 23px;
      width: 15px;
      transform: rotate(0deg) !important;
      transition: all .4s ease;
      top:30% !important;
      right:1% !important;
      svg {
        width:20px;
        height:20px;
      }
    }
    
    .ant-collapse-content-box {
      font-family: FreightSans !important;
      font-size: 18px;
      color: #000;
      opacity:0.6;
      width:100%;
    }
`;


const WrapperContent = styled.div`
    display:flex;
    flex-direction:row;
    align-items:flex-start;
    margin-right:24px;
    img {
      width:24px;
      margin-bottom:0;
      margin-left:2px;
    }
    p {
      max-width:520px;
    }
`

const MiniLogoWrapper = styled.div`
  width: 54px;
  height: 54px;
  background-color: ${colors.mainLight};
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  border-radius: 50%;
  position: relative;
  z-index: 10;
  
`
