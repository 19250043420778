import React from "react"
import styled from "styled-components"

interface AuthorInfoProps {
  data: Record<string, any>
}

const AuthorInfo: React.FunctionComponent<AuthorInfoProps> = ({
  data,
}): React.ReactElement => {
  return (
    <>
      <AuthorWrapper id="what-is">
        <ContentContainer>
          <AuthorImage src={data.image} />
          <Text>
            <AuthorName>{data.name}</AuthorName>
            <CreatedAt>{data.created}</CreatedAt>
          </Text>
        </ContentContainer>
      </AuthorWrapper>
    </>
  )
}

const AuthorWrapper = styled.div`
  padding-top: 30px;
  padding-bottom: 30px;
  background-color: #f0f0f0;
  width: 100%;
`

const ContentContainer = styled.div`
  with: 100%;
  max-width: 800px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
`

const AuthorImage = styled.img`
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin: 0;
  object-fit: cover;
  margin-left: 20px;
  margin-right: 20px;
`

const Text = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: left;
`

const AuthorName = styled.h5`
  font-family: FreightSans;
  font-size: 18px;
  line-height: 1.35;
  font-weight: 600;
  color: #000;
  opacity: 0.9;
  margin-bottom: 0;
`

const CreatedAt = styled.span`
  font-family: FreightSans;
  font-size: 14px;
  line-height: 20px;
  padding-top: 5px;
  color: #000;
  opacity: 0.8;
  width: 100%;
`

export default AuthorInfo
