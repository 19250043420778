import React from "react"
import { GatsbyLocation } from "local-types"
import styled from "styled-components"
import Layout from "../components/Layout"
import { useSiteMetadata } from "../hooks"


import { device } from "../styles/constants"


import LandingPageContent from "../components/LandingPage/LandingPageContent"
import FooterContact from "../components/ContactComponents/FooterContact"
import ScheduleDemo from "../components/LandingPage/ScheduleDemo"
import SEO from "../components/Seo/Seo"
import FlipbaseHero from "../components/FlipbaseHero"

interface WhatIsFlipbase {
  location: GatsbyLocation

}

const heroData = {
  title: "What is Flipbase?",
  subhead: "Integrated asynchronous video communication for recruitment professionals",
  backgroundImageHero: "/workplace_bg.jpg",
  linkToAnchor: "/what-is-flipbase#what-is"
}

const careersMetadata = {
  Title: "What is Flipbase? Integrated video technology",
  Keywords: ["Flipbase", "What is Flipbase?", "What is Flipbase"],
  Description:
    "What is Flipbase? We are an integrated video technology, specialized in integrating asynchronous video communication for recruitment professionals.",
}

const WhatIsFlipbasePage: React.FunctionComponent<WhatIsFlipbase> = ({
  location,
}): React.ReactElement => {
  const { title: siteTitle } = useSiteMetadata()
  return (
    <Layout
      location={location}
      title={siteTitle}
    >
      <SEO
        title={careersMetadata.Title}
        description={careersMetadata.Description}
        keywords={careersMetadata.Keywords}
        
      />
      
        <ContentWrapper>
          <FlipbaseHero
            backgroundImageHero={heroData.backgroundImageHero}
            title={heroData.title}
            subhead={heroData.subhead}
            linkToAnchor={heroData.linkToAnchor}
          />
          <LandingPageContent />
        </ContentWrapper>
        <ScheduleDemo />
        <FooterContact paddingLaptop="20px 120px 20px 120px;" elPosition="relative"  />
    </Layout>
  )
}

const ContentWrapper = styled.div`
  padding-top: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
`




export default WhatIsFlipbasePage



